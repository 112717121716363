/* global PRODUCTION VUE */
// import Vue from 'vue';
// import ExampleSfc from '../components/vue/example-sfc.vue';
import { metrics } from './theme/scripts/ferg-metrics.js';

console.log(`${PRODUCTION ? 'Production Environment' : 'Dev Environment'}`);

if (PRODUCTION) {
	// Production-specific settings go here.
	// console.log = () => {}; 
} else {
	metrics();
}

document.addEventListener('DOMContentLoaded', function() {
	if (!PRODUCTION) console.log('DOMContentLoaded');
	
	// initVue();
	handleHamburgers();
	handleDropdowns();
});

function handleHamburgers() {
	// Get all "navbar-burger" elements
	const navbarBurgers = document.querySelectorAll('.navbar-burger'); // Check if there are any navbar burgers
	
	if (navbarBurgers.length > 0) {
		// Add a click event on each of them
		navbarBurgers.forEach(function(el) {
			el.addEventListener('click', function() {
				// Get the target from the "data-target" attribute
				const target = el.dataset.target;
				const $target = document.getElementById(target); // Toggle the "is-active" class on both the "navbar-burger" and the "navbar-menu"
				
				el.classList.toggle('is-active');
				$target.classList.toggle('is-active');
			});
		});
	}
}

function handleDropdowns() {
	const dropdowns = document.querySelectorAll('.navbar-dropdown'); // Check if there are any navbar burgers
	
	if (dropdowns.length > 0) {
		dropdowns.forEach(function(el) {
			const navbarItem = el.parentElement;
			const toggle = navbarItem.querySelector('.navbar-link');
			
			toggle.addEventListener('click', function() {
				navbarItem.classList.toggle('is-active');
				
				document.addEventListener('click', function _listener(e) {
					console.log(e.target);
					console.log(el);	
					if (!navbarItem.contains(e.target)) {
						navbarItem.classList.toggle('is-active');
						document.removeEventListener('click', _listener);
					}
				});
			});
		});
	}
}

// function initVue() {
// 	if (VUE) {
// 		const scripts = {};
// 		if (VUE === 'SFC') {
// 			// cache WP content that has a <script> tag
// 			// use this for "full page" Vue apps with user content
// 			if (document.querySelectorAll('[v-pre] script').length > 0) {
// 				document.querySelectorAll('[v-pre]').forEach((pre) => {
// 					scripts[pre.id] = pre.innerHTML;
// 					pre.innerHTML = '';
// 				});
// 			}
// 			Vue.use(ExampleSfc);
// 		}
// 		new Vue({
// 			el: '#app',
// 			components: {
// 				ExampleSfc
// 			},
// 			data() {
// 				return {
					
// 				};
// 			},
// 			methods: {
// 				init: function() {
// 					console.log('Vue Initialized');
// 				},
// 			},
// 			mounted: function() {
// 				if (VUE === 'SFC') {
// 					// Re-add any WP content that has a <script> tag
// 					Object.keys(scripts).forEach((key) => {
// 						document.querySelector(`#${key}`).innerHTML = scripts[key];
// 					});
// 				}
// 			},
// 			created: function() {
// 				this.init();
// 			},
// 		});
// 	}
// }
